import React, { memo } from 'react';
import styled from 'styled-components';
import { Routings } from '@/common/routings';
import ContactSupportIcon from '../Icons/ContactSupportIcon';
import CustomLink from '../CustomLink';
import theme from '@/styles/theme';

const StyledLink = styled(CustomLink)<{ vertical: boolean }>`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #d1a827;
  text-decoration: none;
  color: #fff;
  font: ${theme.fonts.bold_20_20};
  & > *:first-child {
    margin: 0 14px 0 0;
  }

  ${({ vertical }) =>
    vertical &&
    `
      width: 54px;
      height: auto;
      writing-mode: vertical-rl;
      padding: 17px;
      & > *:first-child {
        margin: 0 0 14px 0;
      }      
  `}
`;

type Props = {
  serviceType: 'seller' | 'buyer';
  vertical?: boolean;
};

const ClickHereInquiry = (props: Props) => {
  const { serviceType, vertical } = props;
  const url = serviceType === 'buyer' ? Routings.buyerInquiry.location : Routings.sellerInquiry.location;
  return (
    <StyledLink to={url} vertical={!!vertical}>
      <ContactSupportIcon />
      ご相談はこちら
    </StyledLink>
  );
};

export default memo(ClickHereInquiry);
