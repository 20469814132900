/* eslint-disable react/display-name */
import { setPartneridForSessionStorage } from '@/common/utilities';
import { QueryStrings } from '@/constants/constants';
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import Seo from '@/components/seo';

function withInitializeApp<T>(Component: React.ComponentType<T>) {
  return (props: T) => {
    const [partnerId] = useQueryParam(QueryStrings.PARTNER_ID, StringParam);

    useEffect(() => {
      setPartneridForSessionStorage(partnerId);
    }, []);

    return (
      <>
        <Seo />
        <Component {...props} />
      </>
    );
  };
}

export default withInitializeApp;
