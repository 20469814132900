import React, { memo } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  margin-top: 60px;
`;

type Props = {
  children: React.ReactNode;
};

const InquirySection = ({ children }: Props) => {
  return <Section>{children}</Section>;
};

export default memo(InquirySection);
