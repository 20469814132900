import React from 'react';
import Completed from '@/components/organisms/Inquiry/CommonComponent/Completed';
import { Routings } from '@/common/routings';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Wrapper } from '@/components/layout/common';
import { useDirectAccess } from '@/common/hooks';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

const CompletedPage = () => {
  const isDirectAccess = useDirectAccess(Routings.buyerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <Wrapper>
      <BuyerHeader displayPattern="logo" />
      <Completed top={Routings.buyerTop.location} />
      <BuyerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(CompletedPage);
