import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import PageTitle from '@/components/atoms/PageTitle';
import { Breakpoints } from '@/constants/constants';
import { Main } from '@/components/layout/common';
import theme from '@/styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 61px 0 0 0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 17px;
  }
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1062px;
`;

const MessageArea = styled.div`
  width: 100%;
  margin-bottom: 60px;
  vertical-align: top;
  padding: 40px 0;
  background: #f7f7f7;
  border-radius: 10px;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    max-width: 100%;
    padding: 20px 45px;
    vertical-align: baseline;
  }
`;

const Message = styled.p`
  margin-bottom: 40px;
  font: ${theme.fonts.normal_16_32};
  color: #4c586f;
  text-align: center;
`;

const Button = styled.button`
  width: 299px;
  height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font: ${theme.fonts.bold_16_33};
  color: #4c586f;

  opacity: 1;
  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

type Props = {
  top: string;
};

const Completed = (props: Props) => {
  return (
    <Main>
      <PageTitle>お問合せ</PageTitle>
      <Wrapper>
        <ContentArea>
          <MessageArea>
            <Message>
              この度は、KUSABIへのお問合せありがとうございます。
              <br />
              担当者よりメールにてご連絡致しますので、 今しばらくお待ちくださいませ。
            </Message>
            <Button onClick={() => navigate(props.top)}>トップ画面へ</Button>
          </MessageArea>
        </ContentArea>
      </Wrapper>
    </Main>
  );
};

export default Completed;
